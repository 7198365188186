// 给action注册事件处理函数。当这个函数被触发时候，将状态提交到mutations中处理
export function modifyAName({commit}, name) { // commit 提交；name即为点击后传递过来的参数，此时是 'A餐馆'
  return commit('modifyAName', name)
}

export function modifyBName({commit}, name) {
  return commit('modifyBName', name)
}

export function modifyATitle({commit}, name) {
  return commit('modifyATitle', name)
}

export function modifyBTitle({commit}, name) {
  return commit('modifyBTitle', name)
}

export function login({commit}, name) {
  // return commit ('login', name)
  setTimeout(() => {
    alert('登录成功')
    // store.commit(moduleName + '/ROUTE_CHANGED', { to: to, from: from })
    // commit('route/ROUTE_CHANGED', {to: {path: '/componentsB'},from:{path: '/componentsA'}})
    commit('route/ROUTE_CHANGED', {to: {path: '/componentsB'}})
  }, 1000)
}

// login({state, commit}) {
//   setTimeout(() => {
//     alert('登录成功')
//     commit('route/ROUTE_CHANGED',{to: {path: '/b'}})
//   }, 1000)
// }
// ES6精简写法
// export const modifyAName = ({commit},name) => commit('modifyAName', name)
