// 登录信息
import {loginApi, valiApi, setCookie, getCookie, clearCookie} from '@/api/login'
import {Show} from '@/api/show'

const state = {
    nickName: '',
    cartCount: 0,
    username: "",
    wxuserinfo: {},
    uuidSms:'',
    userinfo:{
        userProductList:[],
        userResList:[],
        userFuncList:[],
        userProdList:[],
        user:{
            userId: '',
            custId: '',
            acctId: '',
            serviceNum: '',
            numBelong: '',
            iccid: '',
            createDate: '',
            completeDate: '',
            stopDate: '',
            statusLabel: '',
            offDate: null,
        },
        cust:{
            custId: '',
            custName: '',
            identityType: 1,
            identityNum: '',
            identityAddr: '',
            identityOffice: '',
            identityEffect: '',
            identityExpire: '',
            contactNum: 0,
            nowAddress: '',
            identityImg1: '',
            identityImg2:'',
            identityImg3:'',
            createDate: ''
        },
        userBillList:[],//账单表
        acct:{},//账户
        card:{},
        openid:''
    },
    serviceNum:'',
    phone:''
}
const getters = {}
const mutations = {
    //更新用户信息
    setUserInfo(state, wxuserinfo) {
        state.wxuserinfo = wxuserinfo;
        state.nickName = wxuserinfo.nickname;
    },
    setLogin(state, serviceNum) {
        console.log('验证码')
        state.serviceNum = serviceNum;

    },
    //更新用户信息
    setOpenid(state, openid) {
        state.openid = openid;
    },
    setNumUserInfo(state, userinfo) {
        state.userinfo = userinfo;
        if(Object.keys(userinfo).length>0){
            state.userinfo.user.statusLabel = Show.getuserStatus( state.userinfo.user.status)
            if(state.userinfo.cust.identityNum){
                let  identityNum = state.userinfo.cust.identityNum
                let str1=identityNum.slice(0,6);
                let str2='';
                let str3=identityNum.slice(14);
                if(identityNum.length==15){
                    str2='******';
                }else{
                    str2='********';
                }
                state.userinfo.cust.identityNumLabel  =    str1+str2+str3;
                state.userinfo.cust.identityTypeLabel  =  Show.getidentityTypes(state.userinfo.cust.identityType)
            }
        }

    },
    setuuidSms(state, uuidSms) {
        state.uuidSms = uuidSms;
    },
    updateCartCount(state, cartCount) {
        state.cartCount += cartCount;
    },
    setusername(state, payload) {
        // 改变state里的 username
        state.username = payload
    }
}
const actions = {
// {userName:this.user,userPwd:this.password}
    async toLogin({commit}, {userName, userPwd}) {
        // console.log({commit})
        console.log({userName, userPwd})
        let res = await loginApi(userName, userPwd)
        console.log('vue-wap-----toLoginx执行结果：')
        // console.log(res)
        console.log(res.data)
        let {username, token} = res.data
        // 提交到 mutations
        commit("setusername", username)
        // token 具有时效性 登录成功 把token存在本地存储
        localStorage["token"] = token
        setCookie('token', token)
        // this.$cookies.set(keyName, value[, expireTimes[, path[, domain[, secure]]]])
    },
    async valiApi({commit}) {
        const {username, token} = await valiApi();
        commit('setusername', username);
        localStorage["token"] = token
        setCookie('token', token)
        return username !== undefined;
    },
    async LoginOut() {
        clearCookie('token')
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
